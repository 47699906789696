<template>
  <div class="awards">
    <ComponentGallery :all="true" :showCaption="true" message="Для загрузки изображений, определения их рейтинга и возможности голосования нужно создать профиль и зайти в него по ссылке Логин внизу страницы."/>

    <ComponentClients :showCaption="true"/>
  </div>

  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import ComponentGallery from '@/components/Gallery.vue'
import ComponentClients from '@/components/Clients.vue'

@Options({
  components: {
    ComponentGallery,
    ComponentClients
  },

  mounted () {
    
  }
})
export default class Gallery extends Vue {}
</script>